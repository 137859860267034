import { useToast, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Heading, HStack, Box, Stack, useDisclosure } from "@chakra-ui/react";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { axiosPost } from "../../../hooks/api/utils";
import { OfferEnquiry } from "../../Marketplace/types";
import OfferForm from "./OfferFormBody";
import { FormProvider, useForm } from 'react-hook-form';
import { useCustomToast } from "../../../hooks/useCustomToast";
import LicensePlate from "../../LicensePlate";
import { useCompanyInfo, useCompanyServices } from "../../../hooks/api/companyInfo";
import { useHandledOfferRequestsImmutable } from "../../../hooks/api/offerRequests";
import { useCompanySettings, SETTINGS } from "../../../hooks/api/companySettings";
import { getFirebaseImageUrl } from "../../../utils/firebase";
import Service from "../../Marketplace/Service";
import Reparations from "../../Marketplace/Reparations";
import { Video } from "../../Video";
import ConfirmOfferModal from "./ConfirmOfferModal";
import { ref } from "firebase/storage";
import storage from "../../../utils/firebaseConfig";
import { uploadBytesResumable } from "firebase/storage";

interface AddOfferConfirmationProps {
    isOpenModal: boolean;
    onCloseModal: () => void;
    mutateOfferRequests: () => void;
    enquiry: OfferEnquiry;
}

export function isWorkArticleFieldsUnFinished(data: any) {
    const { articleAmount, articleName, articlePrice } = data;

    if (articleName?.length > 0 && articleAmount?.length > 0 && articlePrice?.length > 0) {
        return true;
    }
    return false
}

// Add a validation helper function
export const validateArticleField = (
    fieldName: 'articlePriceWrite' | 'articleAmountWrite' | 'workArticleSelect',
    value: string | number,
    setArticleFieldError?: (val: any) => void,
    ref?: MutableRefObject<any>
): boolean => {
    const isInvalid = !value || value === 0 || value === '';

    if (isInvalid) {
        document.getElementById(fieldName)?.focus();
        ref?.current?.scrollTo({ top: 0, behavior: 'smooth' });

        setArticleFieldError(prev => ({
            ...prev,
            [fieldName]: true
        }));

        return false;
    }
    return true;
};

// Replace the duplicate validation blocks with:
export const validateArticleFields = (currentArticlePrice: string | number, currentArticleAmount: string | number, currentArticleName: string, setArticleFieldError: (val: any) => void, articleRowRef: MutableRefObject<any>) => {
    // Check price
    if (!validateArticleField('articlePriceWrite', currentArticlePrice, setArticleFieldError, articleRowRef)) {
        return false;
    }

    // Check amount
    if (!validateArticleField('articleAmountWrite', currentArticleAmount, setArticleFieldError, articleRowRef)) {
        return false;
    }

    // Check name
    if (!validateArticleField(
        'workArticleSelect',
        currentArticleName,
        setArticleFieldError,
        articleRowRef
    )) {
        return false;
    }

    return true;
};


const CreateOffer: React.FC<AddOfferConfirmationProps> = ({ isOpenModal, onCloseModal, mutateOfferRequests, enquiry }) => {
    const fileUploadInputRef = useRef(null);
    const toast = useToast();

    const [file, setFile] = useState(null);
    const [isLoadingSaveOffer, setIsLoadingSaveOffer] = useState(false);
    const [hasSavedSuccessfully, setHasSavedSuccessfully] = useState(false);
    const [handInDates, setHandInDates] = useState({});
    const [tab, setTab] = useState('form')
    const [unFinishedWorkArticleError, setUnfinishedWorkArticleError] = useState(false);
    const { mutateHandledOfferRequests } = useHandledOfferRequestsImmutable();
    const { mutateCompanyServices } = useCompanyServices();
    const { settingValue: addonArticlePercentageCost } = useCompanySettings(SETTINGS.ADDON_ARTICLE_PERCENTAGE_COST);
    const isServiceWorkOnly = enquiry?.service && enquiry?.reparations?.length === 0;
    const isReparationWorkOnly = enquiry?.reparations?.length === 1 && enquiry?.reparations?.some(reparation => reparation.category === 'reparation');

    const methods = useForm();
    const drawerBodyRef = useRef<HTMLDivElement>(null);
    const [articleFieldError, setArticleFieldError] = useState<Record<string, boolean>>({});
    const { isOpen: isOpenConfirmOfferModal, onOpen: onOpenConfirmOfferModal, onClose: onCloseConfirmOfferModal } = useDisclosure();
    const [payload, setPayload] = useState(null);

    // These are fields that are currently being edited but not saved yet
    const currentArticlePrice = methods.getValues('articlePrice');
    const currentArticleAmount = methods.getValues('articleAmount');
    const currentArticleName = methods.getValues('articleName');
    const currentTab = methods.getValues('workDetailsFormat');

    const articleRowRef = useRef(null);

    const { companyInfo } = useCompanyInfo();

    const { infoToast } = useCustomToast({ position: 'top-right' });

    useEffect(() => {
        return () => {
            setFile(null);
            setHasSavedSuccessfully(false);
            setIsLoadingSaveOffer(false);
        }
    }, [])

    function resetFields() {
        const { setValue } = methods;
        setValue('priceTotal', null)
        setValue('durationInDays', null)
        setValue('message', null)
        setFile(null)
        setHandInDates({})
    }

    function buildDefaultPayload(data: any) {
        const { durationInDays, availableTimeFrom, availableTimeTo, priceTotal, message } = data;

        const payload = {
            clientId: enquiry.clientId,
            companyName: companyInfo.companyName,
            durationInDays,
            availableTime: availableTimeFrom + '-' + availableTimeTo,
            offerRequestId: enquiry.offerEnquiryId,
            updatedStatus: "offerWaiting",
            availableDates: handInDates,
            priceTotal,
            file: file,
            messageContent: message
        }

        return payload;
    }

    const saveOfferForm = async (data: any) => {

        // When user forgets to add a work article with the PLUS button
        if (isWorkArticleFieldsUnFinished(data)) {
            if (articleRowRef.current) {
                articleRowRef.current.focus()
            }
            setUnfinishedWorkArticleError(true)
            return
        }

        const { durationInDays, availableTimeFrom, availableTimeTo, message, addedWork, laborCost, laborHours } = data;

        const isAnyFieldDirty = currentArticleAmount > 0 || currentArticlePrice > 0 || currentArticleName?.length > 0;

        if ((!isServiceWorkOnly && isAnyFieldDirty) || (isServiceWorkOnly && addedWork?.length === 0) || (isServiceWorkOnly && isAnyFieldDirty)) {
            if ((isServiceWorkOnly && addedWork?.length === 0)) {
                infoToast('Material saknas', 'Vänligen lägg till material för att kunna skapa en offert för service')
            }
            if (!validateArticleFields(currentArticlePrice, currentArticleAmount, currentArticleName, setArticleFieldError, articleRowRef)) {
                drawerBodyRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
                return;
            }
        }

        if (Object.keys(handInDates).length < 1) {
            return infoToast('Välj inlämningsdatum i kalendern', "Vi rekommenderar att du ger kunden flera datum att välja mellan")
        }

        const { error, message: errorMessage } = isHandInDatesValid();
        if (error) {
            return infoToast(errorMessage)
        }

        setPayload({
            availableDates: handInDates,
            workDetails: addedWork || [],
            offerRequestId: enquiry.offerEnquiryId,
            updatedStatus: 'offerWaiting',
            messageContent: message,
            clientId: enquiry?.clientId,
            companyName: companyInfo?.companyName,
            durationInDays: durationInDays,
            availableTime: availableTimeFrom + '-' + availableTimeTo,
            addonArticlePercentageCost: addonArticlePercentageCost,
            isServiceWorkOnly,
            laborCost,
            laborHours
        })

        setArticleFieldError({})
        onOpenConfirmOfferModal()
        return

    }

    async function handleConfirmAndSendOfferByForm() {
        setIsLoadingSaveOffer(true)

        const { error, message } = isHandInDatesValid();
        if (error) {
            setIsLoadingSaveOffer(false)
            return infoToast(message)
        }

        try {
            await axiosPost('/api/offers/offers-v1/by-form', payload)

            setIsLoadingSaveOffer(false)
            setUnfinishedWorkArticleError(false)
            setHasSavedSuccessfully(true)
            resetFields();
            mutateOfferRequests();
            mutateHandledOfferRequests();
            mutateCompanyServices();

        } catch (error) {
            setHasSavedSuccessfully(false)
            setIsLoadingSaveOffer(false)
            console.log(error);

            return toast({
                title: 'Något gick fel',
                position: 'top-right',
                description: "Kontrollera så du har fyllt i fälten korrekt",
                status: 'warning',
                duration: 5000,
                isClosable: true,
            })
        }
    }

    const cleanFileName = (fileName: string): string => {
        return fileName
            // Remove hashtags
            .replace(/#/g, '')
            // Remove special characters but keep dots, hyphens, and underscores
            .replace(/[^a-zA-Z0-9\s._-]/g, '')
            // Remove multiple spaces
            .replace(/\s+/g, ' ')
            .trim();
    };

    async function handleConfirmAndSendOfferByFile() {
        setIsLoadingSaveOffer(true)
        try {
            if (file) {
                // upload to firebase
                const fileName = `${file?.lastModified}_${cleanFileName(file?.name)}`;

                const storageRef = ref(storage, fileName);
                const uploadTask = uploadBytesResumable(storageRef, file);

                await uploadTask.then(() => {
                    // Assign file to payload when upload is complete
                    payload.file = {
                        filename: fileName,
                    }
                    console.log('File uploaded...')
                }).catch((error) => {
                    console.log(error)
                    return infoToast('Något gick fel', 'Vänligen försök igen senare eller ring oss för att hjälpa dig')
                })
            } else {
                setIsLoadingSaveOffer(false)
                return infoToast('Vänligen lägg till en fil för arbetsdetaljer');
            }
        } catch (error) {
            setIsLoadingSaveOffer(false)
            return infoToast('Något gick fel', 'Vänligen försök igen senare eller ring oss för att hjälpa dig')
        }

        try {
            await axiosPost('/api/offers/offers-v1', payload)

            setUnfinishedWorkArticleError(false)
            setHasSavedSuccessfully(true)
            setIsLoadingSaveOffer(false)
            resetFields();
            mutateOfferRequests();
            mutateHandledOfferRequests();
            mutateCompanyServices();

        } catch (error) {
            setHasSavedSuccessfully(false)
            setIsLoadingSaveOffer(false)
            return toast({
                title: 'Något gick fel',
                position: 'top-right',
                description: "Kontrollera så du har fyllt i fälten korrekt",
                status: 'warning',
                duration: 5000,
                isClosable: true,
            })
        }
    }

    const saveOfferFile = async (data: any) => {
        if (!file) {
            return infoToast('Vänligen lägg till en fil för arbetsdetaljer');
        }

        if (Object.keys(handInDates).length < 1) {
            return infoToast('Välj inlämningsdatum i kalendern', "Vi rekommenderar att du ger kunden flera datum att välja mellan")
        }

        const { error, message } = isHandInDatesValid();
        if (error) {
            return infoToast(message)
        }

        setPayload(buildDefaultPayload(data));
        onOpenConfirmOfferModal()
    }

    function handleSaveOffer(data: any) {
        const saveByFileSelected = tab;
        if (saveByFileSelected === 'form') {
            return saveOfferForm(data)
        }

        return saveOfferFile(data)
    }

    function isHandInDatesValid() {
        if (Object.keys(handInDates).length === 0) {
            return { error: true, message: 'Vänligen välj inlämningsdatum i kalendern' }
        }
        return { error: false, message: null }
    }

    function renderEnquiryOverview() {
        return (
            <Box pos={'fixed'} overflowY={'auto'} top={0} left={'-470px'} zIndex={9999} w={'70%'} maxH={'100vh'} h={'auto'} bg={'white'} boxShadow={'2xl'} p={4} borderTopLeftRadius={12} borderBottomLeftRadius={12}>
                <Heading size={'sm'}>Kundens offertförfrågan</Heading>
                {enquiry?.reparations?.map((reparation, i) => {
                    const mimeType = reparation?.attachments?.[0]?.mimeType;
                    if (mimeType?.includes('image')) {
                        return (
                            <img key={i} src={getFirebaseImageUrl(reparation.attachments[0].fileName)} alt={'some image'} height={'300px'} style={{ borderRadius: 12 }} />
                        )
                    }

                    if (mimeType?.includes('video')) {
                        return <Video selectedVideo={getFirebaseImageUrl(reparation.attachments[0].fileName)} w="400" h="100" />
                    }
                    return null;
                })}
                <Stack spacing={4} pt={6} pb={4}>
                    <Reparations enquiry={enquiry} fontSize={[12, 14]} hideImageText />
                    <Service enquiry={enquiry} fontSize={[12, 14]} />
                </Stack>
            </Box>)
    }

    const renderDrawerBody = () => {
        if (!hasSavedSuccessfully) {
            return (
                <Box>
                    {renderEnquiryOverview()}
                    <OfferForm
                        articleFieldError={articleFieldError}
                        setArticleFieldError={setArticleFieldError}
                        unFinishedWorkArticleError={unFinishedWorkArticleError}
                        setUnfinishedWorkArticleError={setUnfinishedWorkArticleError}
                        enquiry={enquiry}
                        file={file}
                        fileUploadInputRef={fileUploadInputRef}
                        setFile={setFile}
                        onSaveOffer={handleSaveOffer}
                        setHandInDates={setHandInDates}
                        handInDates={handInDates}
                        tab={tab}
                        setTab={setTab}
                        articleRowRef={articleRowRef}
                    />
                </Box>
            )
        }
    }

    return (
        <>
            <Drawer
                isOpen={isOpenModal}
                onEsc={() => hasSavedSuccessfully && sessionStorage.removeItem(enquiry.offerEnquiryId)}
                onOverlayClick={() => hasSavedSuccessfully && sessionStorage.removeItem(enquiry.offerEnquiryId)}
                onClose={() => {
                    onCloseModal()
                    setFile(null)
                    methods.setValue('priceTotal', null);
                    methods.setValue('durationInDays', null);
                    methods.setValue('availableTimeFrom', null);
                    methods.setValue('availableTimeTo', null);
                    setArticleFieldError({})
                    setUnfinishedWorkArticleError(false)
                    setHasSavedSuccessfully(false);
                    setIsLoadingSaveOffer(false);

                    if (hasSavedSuccessfully) {
                        sessionStorage.removeItem(enquiry.offerEnquiryId)
                    }
                }}
                size={'lg'}
            >
                <DrawerOverlay />
                <DrawerContent >
                    <DrawerCloseButton />
                    <DrawerHeader fontSize={16}>
                        <HStack justify={'space-between'} >
                            <Heading size={'md'}>Ny offert</Heading>
                            <Box pr={12}>
                                <LicensePlate regNo={enquiry?.regNo} size='xs' />
                            </Box>
                        </HStack>
                    </DrawerHeader>
                    <DrawerBody padding={0} ref={drawerBodyRef}>
                        <FormProvider {...methods}>
                            <ConfirmOfferModal
                                isReparationWorkOnly={isReparationWorkOnly}
                                isLoading={isLoadingSaveOffer}
                                payload={payload}
                                onConfirm={() => {
                                    if (currentTab === 'form') {
                                        handleConfirmAndSendOfferByForm()
                                    } else {
                                        handleConfirmAndSendOfferByFile()
                                    }
                                }}
                                isOpenModal={isOpenConfirmOfferModal}
                                onCloseModal={() => {
                                    if (hasSavedSuccessfully) {
                                        onCloseModal()
                                    }
                                    onCloseConfirmOfferModal()
                                    setHasSavedSuccessfully(false)
                                    sessionStorage.removeItem(enquiry.offerEnquiryId)
                                }}
                                isSuccess={hasSavedSuccessfully}
                                setIsSuccess={setHasSavedSuccessfully}
                            />
                            {renderDrawerBody()}
                        </FormProvider>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default CreateOffer;
