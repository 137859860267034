import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, useMediaQuery, Text, Button, ModalFooter, ModalHeader, Stack, HStack, Heading, Divider, Box, Badge, Tbody, Table, Td, Th, Thead, Tr, Tooltip, Alert, AlertIcon, Checkbox, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Icon } from "@chakra-ui/react"
import { getVatCost, formatMoney, units, getLaborCostSum, getCostSum } from "./AddWorkDetailsForm";
import { SETTINGS, useCompanySettings } from "../../../hooks/api/companySettings";
import { InfoIcon } from "@chakra-ui/icons";
import OfferSendSuccess from "./OfferSendSuccessBody";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FaPaperclip } from "react-icons/fa";

interface ConfirmOfferModalProps {
    isOpenModal: boolean;
    onCloseModal: () => void;
    payload: any;
    onConfirm: () => void;
    isLoading: boolean;
    isSuccess: boolean;
    setIsSuccess: (isSuccess: boolean) => void;
    isReparationWorkOnly?: boolean;
}

// Helper function to safely create object URL
const getFileUrl = (file: any) => {
    try {
        if (file instanceof Blob || file instanceof File) {
            return URL.createObjectURL(file);
        }
        return null;
    } catch (error) {
        console.error('Error creating object URL:', error);
        return null;
    }
};

const ConfirmOfferModal: React.FC<ConfirmOfferModalProps> = ({ isOpenModal, onCloseModal, payload, onConfirm, isLoading, isSuccess, setIsSuccess, isReparationWorkOnly }) => {
    const [isMobile] = useMediaQuery('(max-width: 768px)')
    const { companySettings } = useCompanySettings();
    const addonArticleCostSetting = companySettings?.find(val => val.name === SETTINGS.ADDON_ARTICLE_PERCENTAGE_COST)?.value
    const [acceptPrice, setAcceptPrice] = useState(false);
    const shouldShowAcceptPrice = isReparationWorkOnly && payload?.workDetails?.length === 0 ? true : false;
    const { getValues } = useFormContext();
    const currentTab = getValues('workDetailsFormat');

    useEffect(() => {
        const objectUrl = getFileUrl(payload?.file);

        // Cleanup function
        return () => {
            if (objectUrl) {
                URL.revokeObjectURL(objectUrl);
            }
        };
    }, [payload?.file]);

    if (!payload) {
        return null;
    }


    const { sum, addonSum } = getCostSum(payload.workDetails, addonArticleCostSetting);
    const sumTotal = sum + addonSum + getLaborCostSum(payload.laborCost, payload.laborHours);

    function successContent() {
        return (
            <Box p={4}>
                <OfferSendSuccess onCloseModal={() => {
                    onCloseModal();
                    setIsSuccess(false);
                    sessionStorage.removeItem(payload.enquiryId)
                }} />
            </Box>
        )
    }

    function renderFormWorkDetails() {
        return (
            <Stack spacing={4}>
                {payload?.workDetails?.length > 0 ? (
                    <Stack>
                        <Heading fontSize={'md'} fontWeight={'bold'}>
                            Reservdelar / Material
                        </Heading>
                        <Stack spacing={2}>
                            <Table variant="simple" size="sm">
                                <Thead>
                                    <Tr>
                                        <Th fontSize={14}>Benämning</Th>
                                        <Th fontSize={14} isNumeric>Antal</Th>
                                        <Th fontSize={14} isNumeric>À-Pris</Th>
                                        <Th fontSize={14} isNumeric>Påslag</Th>
                                        <Th fontSize={14} isNumeric>Belopp</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {payload.workDetails?.map((work: any, i: number) => (
                                        <Tr key={i}>
                                            <Td fontSize={16}>{work.articleName}</Td>
                                            <Td fontSize={16} isNumeric>
                                                {work.articleAmount} {units.find((unit: any) => unit.key === work.articleUnit)?.abbreviation}
                                            </Td>
                                            <Td fontSize={16} isNumeric>{formatMoney(work.articlePrice)}</Td>
                                            <Td fontSize={16} isNumeric>{addonArticleCostSetting || 0}%</Td>
                                            <Td fontSize={16} isNumeric>{formatMoney((work.articlePrice * work.articleAmount) * (1 + parseFloat(addonArticleCostSetting || '0') / 100))}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </Stack>
                    </Stack>
                ) : shouldShowAcceptPrice ? (
                    <Stack spacing={2} pb={4}>
                        <Alert fontSize={14} status='info' borderRadius={12}>
                            <AlertIcon />
                            <Text>Observera: Du har inte lagt till några reservdelar / material. Ditt pris mot kunden kommer att baseras på endast arbetskostnaden och är beloppet som kunden betalar för arbetet.</Text>
                        </Alert>

                        <Checkbox colorScheme='teal' size='lg' onChange={() => {
                            setAcceptPrice(!acceptPrice);
                        }}>
                            <Text fontSize={14}>Jag förstår att detta är priset som jag ger kunden för det här arbetet.</Text>
                        </Checkbox>
                    </Stack>
                ) : (
                    <></>
                )}

                <Stack spacing={2} pr={4} pl={4}>
                    <Box>
                        <HStack justify={'space-between'} align='start'>
                            <Text>Materialkostnad</Text>
                            <Text data-testid='material-cost-text'>{formatMoney(sum + addonSum)}</Text>
                        </HStack>
                        <HStack justify={'space-between'} align='start'>
                            <HStack>
                                <Text fontSize={14}>Varav påslag ({addonArticleCostSetting || 0}%)</Text>
                                <Tooltip hasArrow placement='top' bg={'teal.800'} color={'white'} borderRadius={12} label='Påslaget på reservdelar syns inte för kunden'>
                                    <InfoIcon fontSize={14} />
                                </Tooltip>
                            </HStack>
                            <Text fontSize={14} data-testid='labour-cost-text'>{formatMoney(addonSum)}</Text>
                        </HStack>
                    </Box>

                    <HStack justify={'space-between'} align='start'>
                        <Box>
                            <Text>Arbetskostnad</Text>
                            <Text fontSize={14}>{payload.laborHours} timmar à {payload.laborCost} kr</Text>
                        </Box>
                        <Text data-testid='labour-cost-text'>{formatMoney(getLaborCostSum(payload.laborCost, payload.laborHours))}</Text>
                    </HStack>
                    <Divider pt={2} mb={2} />

                    <Stack spacing={1}>
                        <HStack justify={'space-between'} align='start'>
                            <Heading size={'sm'}>Totalt <Text fontSize={14} fontWeight={'normal'} as='span'>inkl. moms</Text></Heading>
                            <Heading size={'sm'} data-testid='total-cost-text'>{formatMoney(sumTotal)}</Heading>
                        </HStack>
                        <HStack justify={'space-between'} align='start'>
                            <Text fontSize={14}>{'Varav moms (25%)'}</Text>
                            <Text fontSize={14} data-testid='vat-cost-text'>{formatMoney(getVatCost(sumTotal))}</Text>
                        </HStack>
                    </Stack>
                </Stack>
            </Stack>
        )
    }

    function renderFileWorkDetails() {
        if (!payload.file) {
            return null;
        }

        return (
            <Stack spacing={4}>
                <Heading pt={4} size={'sm'}>Arbetsdetaljer i filformat</Heading>
                <Accordion allowToggle>
                    <AccordionItem border={'none'} background='gray.50' borderRadius={'md'} _hover={{ background: 'gray.100', borderRadius: 'md' }}>
                        <AccordionButton>
                            <HStack width={'100%'} justifyContent={'space-between'}>
                                <HStack>
                                    <Icon color="teal.500" as={FaPaperclip} />
                                    <Text>{payload.file.name}</Text>
                                </HStack>
                                <AccordionIcon />
                            </HStack>
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <embed
                                src={getFileUrl(payload.file) || ''}
                                height={400}
                                width={'100%'}
                                type={'application/pdf'} />
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Stack>
        )
    }

    function renderWorkDetails() {
        if (currentTab === 'form') {
            return renderFormWorkDetails()
        }
        return renderFileWorkDetails()
    }

    return (
        <Modal isOpen={isOpenModal} onClose={() => {
            onCloseModal()
        }} size={isMobile ? 'full' : '2xl'}>
            <ModalOverlay />
            <ModalContent >
                {isSuccess ? successContent() :
                    <>
                        <ModalCloseButton />
                        <ModalHeader>
                            <Text fontSize={'lg'} fontWeight={'bold'}>
                                Bekräfta och skicka din offert
                            </Text>
                        </ModalHeader>
                        <ModalBody>
                            <Stack spacing={10}>
                                <Stack spacing={1}>
                                    <Heading pb={1} fontSize={'md'} fontWeight={'bold'}>Inlämningsdatum och inlämningstid</Heading>
                                    <Text>Tillgängliga datum: {payload?.availableDates && Object.keys(payload?.availableDates).sort((a: string, b: string) => new Date(a).getTime() - new Date(b).getTime())?.map((date: string) => {
                                        return (
                                            <Badge key={date} colorScheme='teal' size={'sm'} ml={2}>{date}</Badge>
                                        )
                                    })}</Text>
                                    <Text>Kundens inlämningstid: <Text as='span' fontWeight={'bold'}>{payload.availableTime}</Text></Text>
                                    <Text>Tidsåtgång: <Text as='span' fontWeight={'bold'}>{payload.durationInDays === '0' ? `${payload.durationInDays} dagar (färdig samma dag som inlämning)` : `${payload.durationInDays} dagar`}</Text></Text>
                                </Stack>

                                {payload?.messageContent &&
                                    <Stack>
                                        <Heading fontSize={'md'} fontWeight={'bold'}>
                                            Kommentar
                                        </Heading>
                                        <Text>{payload.messageContent}</Text>
                                    </Stack>
                                }

                                {renderWorkDetails()}
                            </Stack>
                        </ModalBody>
                        <ModalFooter pt={6}>
                            <Button
                                colorScheme='teal'
                                size={'md'}
                                variant='primary'
                                type='submit'
                                isLoading={isLoading}
                                onClick={onConfirm}
                                isDisabled={shouldShowAcceptPrice && !acceptPrice}
                            >
                                Bekräfta och skicka
                            </Button>
                        </ModalFooter>
                    </>
                }
            </ModalContent>
        </Modal>
    )
}

export default ConfirmOfferModal;