import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Heading, HStack, Icon, Stack, useDisclosure, Text, FormControl, FormErrorMessage, Input, InputGroup, InputLeftElement, InputRightElement, Textarea, Select, FormLabel, Switch, Tooltip } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import CreateBookingForm from "../components/DrawerContent/CreateBookingForm";
import { useForm, FormProvider } from 'react-hook-form'
import { axiosPost } from "../hooks/api/utils";
import moment from "moment";
import { useCustomToast } from "../hooks/useCustomToast";
import { useBookingsPerYearMonth, useCurrentUnfinishedBookings } from "../hooks/api/booking";
import { CreateBookingProvider } from "../context/CreateBookingContext";
import { useLocation, useNavigate } from "react-router-dom";
import { FaCarAlt, FaEnvelope, FaEnvelopeOpenText, FaPhoneAlt, FaPlus, FaUserAlt } from "react-icons/fa";
import { DayPickerSingleSelect } from "../components/Marketplace/DayPickerV2";
import HandInTimeIntervalSetting, { getFormattedTimeInterval } from "../components/Settings/HandInTimeInverval";
import { useCompanySettings, SETTINGS } from "../hooks/api/companySettings";
import ReactGA from 'react-ga4'
import { useCompanyInfo, useCompanyServices } from "../hooks/api/companyInfo";
import { ArticleDetails, VALUE_TYPE } from "../components/modals/AddOffer/AddWorkDetailsForm";
import { isWorkArticleFieldsUnFinished, validateArticleFields } from "../components/modals/AddOffer/CreateOffer";
import { InfoIcon } from "@chakra-ui/icons";
import { useCompanyFeatures } from "../hooks/api/feature";

const CreateBooking: React.FC = () => {
    const { mutateBooking } = useBookingsPerYearMonth(moment(Date.now()).format('YYYY-MM'));
    const { mutateBooking: mutateUnfinishedBookings } = useCurrentUnfinishedBookings();
    const { features } = useCompanyFeatures();
    const { successToast, infoToast } = useCustomToast({ duration: 5000 });
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpenIntervalSetting, onOpen: onOpenIntervalSetting, onClose: onCloseIntervalSetting } = useDisclosure()
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { companySettings } = useCompanySettings();
    const [tab, setTab] = useState('form')
    const [unFinishedWorkArticleError, setUnfinishedWorkArticleError] = useState(false);
    const { mutateCompanyServices } = useCompanyServices();
    const [articleFieldError, setArticleFieldError] = useState<Record<string, boolean>>({});

    const handIntTimeIntervalSettingValue = companySettings?.find(val => val.name === SETTINGS.HAND_IN_TIME_INTERVAL)?.value
    const addonArticleCostSettingValue = companySettings?.find(val => val.name === SETTINGS.ADDON_ARTICLE_PERCENTAGE_COST)?.value

    const { fromTime, toTime } = getFormattedTimeInterval(handIntTimeIntervalSettingValue)
    const hasSmsCampaign = features?.some(feature => feature === 'free-sms-campaign')
    
    const articleRowRef = useRef(null);
    const drawerBodyRef = useRef<HTMLDivElement>(null);

    const methods = useForm();
    const { setValue, getValues } = methods;
    const errors = methods.formState.errors;
    const fromDateForm = getValues('availableTimeFrom')
    const toDateForm = getValues('availableTimeTo')
    // These are fields that are currently being edited but not saved yet
    const currentArticlePrice = methods.getValues('articlePrice');
    const currentArticleAmount = methods.getValues('articleAmount');
    const currentArticleName = methods.getValues('articleName');

    const { companyInfo } = useCompanyInfo();
    const { attachment, regNo, email, phoneNo, priceTotal } = getValues();
    const isEmptyForm = !attachment && !regNo && !email && !phoneNo && !priceTotal;

    function resetFormValues() {
        setValue('regNo', undefined)
        setValue('email', undefined)
        setValue('phoneNo', undefined)
        setValue('attachment', undefined)
        setValue('priceTotal', undefined)
    }

    useEffect(() => {
        methods.setValue('availableTimeFrom', fromTime)
        methods.setValue('availableTimeTo', toTime)
    }, [fromTime, toTime, methods])

    async function onSubmit(values: any) {
        if (!values?.selectedDate) {
            return infoToast('Välj kalenderdag innan du bokar')
        }
        if (!values?.regNo) {

            return infoToast('Välj Registreringsnummer')
        }

        if (!values.availableTimeFrom || !values.availableTimeTo) {
            return infoToast('Välj tid för inlämning')
        }

        if (isWorkArticleFieldsUnFinished(values)) {
            if (articleRowRef.current) {
                articleRowRef.current.focus()
            }
            setUnfinishedWorkArticleError(true)
            return;
        }

        const isAnyFieldDirty = currentArticleAmount > 0 || currentArticlePrice > 0 || currentArticleName?.length > 0;

        if (isAnyFieldDirty) {
            if (!validateArticleFields(currentArticlePrice, currentArticleAmount, currentArticleName, setArticleFieldError, articleRowRef)) {
                return;
            }
        }

        const isInvalidWorkArticles = values?.addedWork?.some((work: ArticleDetails) => work.valueType === VALUE_TYPE.SPARE_PARTS && (work?.articleName?.length === 0 || work?.articleAmount?.length === 0 || work?.articlePrice?.length === 0))

        if (isInvalidWorkArticles) {
            return infoToast('Du saknar artikelinformation')
        }

        const formData = new FormData();

        const { attachment, ...rest } = values

        const payload = {
            ...rest,
            companyName: companyInfo.companyName,
            companyAddress: companyInfo.fullAddress,
            companyEmail: companyInfo.companyEmail,
            companyPhoneNo: companyInfo.companyPhoneNo,
            addonArticlePercentageCost: addonArticleCostSettingValue,
            pickupDate: moment(values.selectedDate).add(values.durationInDays, 'days').format('YYYY-MM-DD'),
        }

        const jsonPayload = JSON.stringify(payload);
        formData.append('payload', jsonPayload);
        formData.append("attachment", attachment);

        try {
            const createBookingResponse = await axiosPost('/api/bookings/bookings-v1/company/create', formData, {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
            })
            await mutateBooking();
            mutateCompanyServices();

            if (values.selectedDate === moment(new Date()).format('YYYY-MM-DD')) {
                mutateUnfinishedBookings();
            }
            successToast('Bokningen har skapats framgångsrikt')

            // Reset form and datepicker only on success
            methods.reset();
            onClose();
            // Force rerender when we create a booking whilst already inside the /bookings, otherwise it won't highlight
            return navigate(`/bookings?highlight=${createBookingResponse.data.bookingId as string}`, { state: { forceRerender: pathname === '/bookings' ? true : false } });
        } catch (error) {
            console.log('error', error)
            infoToast('Ett fel inträffade när du försökte skapa en ny bokning')
        }
    }

    function click(action: string) {
        ReactGA.event({
            category: 'Bookings',
            action: action,
            label: "Testa", // optional
            value: 99, // optional, must be a number
        });
    }

    const renderWarningMessage = (errorMessage) => (
        <Text color={'red.500'} fontSize={12}>{errorMessage}</Text>
    )

    return (
        <Box>
            <Drawer
                isOpen={isOpen}
                onClose={onClose}
                size={'lg'}
            >
                <DrawerOverlay />
                <DrawerContent >
                    <DrawerCloseButton />
                    <DrawerHeader fontSize={16}>Skapa ny bokning</DrawerHeader>
                    <DrawerBody padding={0} ref={drawerBodyRef}>
                        <CreateBookingProvider>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                <Stack spacing={4} width={'100%'} margin={'0 auto'}>
                                    <FormProvider {...methods}>
                                        <Stack p={[3, 6]}>
                                            <Stack spacing={0}>
                                                <FormControl isInvalid={'regNo' in errors}>
                                                    <InputGroup size={'lg'}>
                                                        <InputLeftElement
                                                            pointerEvents='none'
                                                            children={<Icon as={FaCarAlt} color='gray.300' />}
                                                        />
                                                        <Input
                                                            textTransform={'uppercase'}
                                                            id='regNo'
                                                            max={6}
                                                            autoFocus
                                                            data-testid='new-booking-regno'
                                                            placeholder='Registreringsnummer'
                                                            {...methods.register('regNo', {
                                                                required: 'Du behöver fylla i registreringsnummer',
                                                                minLength: { value: 6, message: 'Registreringsnumret måste vara 6 tecken' },
                                                                maxLength: { value: 6, message: 'Registreringsnumret måste vara 6 tecken' },
                                                                pattern: { value: RegExp('^(?![iIqQvV])[A-Za-z]{3}[0-9]{2}(?![iIqQvV])[0-9A-Za-z]$'), message: 'Felaktigt registreringsnummer' },
                                                                onChange(event) {
                                                                    return event.target.value.toUpperCase();
                                                                },
                                                            })}
                                                        />
                                                    </InputGroup>
                                                    <FormErrorMessage fontSize={12}>
                                                        {errors?.regNo?.message as string}
                                                    </FormErrorMessage>
                                                </FormControl>
                                                <CreateBookingForm articleFieldError={articleFieldError} setArticleFieldError={setArticleFieldError} articleRowRef={articleRowRef} tab={tab} setTab={setTab} unFinishedWorkArticleError={unFinishedWorkArticleError} setUnfinishedWorkArticleError={setUnfinishedWorkArticleError} />
                                                <Stack pt={8} spacing={6}>
                                                    <Heading size={'sm'}>Tillgänglighet</Heading>
                                                    <FormControl isInvalid={'durationInDays' in errors}>
                                                        <Text fontSize={12}>Uppskatta tidsåtgång</Text>
                                                        <InputGroup>
                                                            <Select width={'100%'} data-testid='work-duration-select' size={'lg'} variant={'outline'} placeholder={'Välj antal arbetsdagar'} bg={'white'} borderRadius={'lg'} {...methods.register('durationInDays', {
                                                                required: 'Sätt ett ungefär på hur många dagar arbetet kommer ta att utföra',
                                                                onChange(event) {
                                                                    return event.target.value;
                                                                }
                                                            })}>
                                                                {Array.from({ length: 12 }, (v, i) => i).map((workDayAmount, i) => {
                                                                    return (
                                                                        <option value={workDayAmount} defaultValue={0} key={i}>{workDayAmount} dagar {workDayAmount === 0 && '(Färdig samma dag som inlämning)'}</option>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </InputGroup>
                                                        <FormErrorMessage>
                                                            {errors?.durationInDays?.message as string}
                                                        </FormErrorMessage>
                                                    </FormControl>
                                                    <Stack>
                                                        <Box pb={2}>
                                                            <Heading size={'xs'}>Välj datum och tid när kunden ska lämna in sin bil</Heading>
                                                            <Text fontSize={12}>Inlämningstid och datum visas för kunden och hjälper de att veta när de ska lämna in bilen hos er.</Text>
                                                        </Box>
                                                        <HStack align={'center'} spacing={4}>
                                                            <FormControl isInvalid={'availableTimeFrom' in errors}>
                                                                <Text fontSize={12}>Inlämning från</Text>
                                                                <InputGroup>
                                                                    <Input
                                                                        size={'lg'}
                                                                        type='time'
                                                                        defaultValue={fromDateForm}
                                                                        placeholder='Inlämningstid Från'
                                                                        data-testid='hand-in-time-from-input'
                                                                        {...methods.register('availableTimeFrom', {
                                                                            required: 'Sätt en från-tid för när bilen kan hämtas',
                                                                            onChange(event) {
                                                                                return event.target.value;
                                                                            }
                                                                        })}
                                                                    />
                                                                </InputGroup>

                                                                <FormErrorMessage>
                                                                    {errors?.availableTimeFrom?.message as string}
                                                                </FormErrorMessage>
                                                            </FormControl>
                                                            <FormControl isInvalid={'availableTimeTo' in errors}>
                                                                <Text fontSize={12}>Inlämning till</Text>

                                                                <InputGroup>
                                                                    <Input
                                                                        type='time'
                                                                        size={'lg'}
                                                                        defaultValue={toDateForm}
                                                                        placeholder='Inlämningstid Till'
                                                                        data-testid='hand-in-time-to-input'
                                                                        {...methods.register('availableTimeTo', {
                                                                            required: 'Sätt en till-tid för när bilen kan hämtas',
                                                                            onChange(event) {
                                                                                return event.target.value;
                                                                            }
                                                                        })}
                                                                    />
                                                                </InputGroup>
                                                                <FormErrorMessage>
                                                                    {errors?.availableTimeTo?.message as string}
                                                                </FormErrorMessage>
                                                            </FormControl>
                                                            <HandInTimeIntervalSetting onClose={onCloseIntervalSetting} onOpen={onOpenIntervalSetting} isOpen={isOpenIntervalSetting} />
                                                        </HStack>
                                                    </Stack>
                                                    <DayPickerSingleSelect />
                                                </Stack>
                                            </Stack>

                                            <Stack spacing={4} pb={16}>
                                                <Box pt={4}>
                                                    <Heading size={'sm'}>Kontaktuppgifter</Heading>
                                                </Box>
                                                <Box>
                                                    <Text fontSize={12}>Fyll i kundens e-post så att kunden får en bokningsbekräftelse</Text>
                                                    <InputGroup alignItems={'center'}>
                                                        <InputLeftElement
                                                            height={'100%'}
                                                            pointerEvents='none'
                                                            children={<Icon as={FaEnvelope} color='gray.300' />}
                                                        />
                                                        <Input
                                                            size={'lg'}
                                                            fontSize={16}
                                                            id='email'
                                                            type='email'
                                                            placeholder='Kundens E-postaddress'
                                                            data-testid='customer-email-input'
                                                            {...methods.register('email')}
                                                        />
                                                    </InputGroup>
                                                    {renderWarningMessage(errors.email && errors.email.message)}
                                                </Box>
                                                <Box>
                                                    <FormControl isInvalid={'fullName' in errors}>
                                                        <InputGroup>
                                                            <InputLeftElement
                                                                height={'100%'}
                                                                pointerEvents='none'
                                                                children={<Icon as={FaUserAlt} color='gray.300' />}
                                                            />
                                                            <Input
                                                                size={'lg'}
                                                                fontSize={16}
                                                                id='fullName'
                                                                required={false}
                                                                placeholder='Kundens Namn och Efternamn'
                                                                {...methods.register('fullName', {
                                                                    pattern: {
                                                                        value: /^[A-Za-zÅÄÖåäö\-\s]*$/,
                                                                        message: 'Endast bokstäver, mellanslag och namn med bindestreck är tillåtna',
                                                                    },
                                                                    required: false
                                                                })}
                                                            />
                                                        </InputGroup>
                                                        <FormErrorMessage fontSize={12}>
                                                            {errors.fullName && errors.fullName.message as string}
                                                        </FormErrorMessage>
                                                    </FormControl>

                                                </Box>
                                                <Box>
                                                    <InputGroup gap={4}>
                                                        <InputLeftElement
                                                            height={'100%'}
                                                            pointerEvents='none'
                                                            children={<Icon as={FaPhoneAlt} color='gray.300' />}
                                                        />
                                                        <Input
                                                            size={'lg'}
                                                            id='phoneNo'
                                                            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                                            type='number'
                                                            fontSize={16}
                                                            placeholder='Kundens Telefonnummer'
                                                            data-testid='customer-phone-input'
                                                            {...methods.register('phoneNo', {
                                                                required: methods.getValues('smsConfirmation') && 'För att skicka bokningsbekräftelse via sms krävs ett telefonnummer',
                                                                minLength: { value: 5, message: 'Ogiltigt telefonnummer' },
                                                                maxLength: { value: 14, message: 'Ogiltigt telefonnummer' },
                                                            })}
                                                        />
                                                    </InputGroup>
                                                    {renderWarningMessage(errors.phoneNo && errors.phoneNo.message)}
                                                </Box>
                                                <Box>
                                                    <FormControl display='flex' alignItems='center'>
                                                        <Switch id='smsConfirmation' {...methods.register('smsConfirmation')} data-testid='sms-confirmation-switch' />
                                                        <FormLabel htmlFor='smsConfirmation' mb='0' ml={2} fontSize={14}>
                                                            Skicka sms-bekräftelse till kund?
                                                        </FormLabel>
                                                        <Tooltip
                                                            hasArrow
                                                            bg='teal.600'
                                                            color='white'
                                                            placement='top'
                                                            label={'Ett sms skickas automatiskt till kund med en bekräftelse. Fråga alltid kunden om tillåtelse att skicka bekräftelse.'}
                                                        >
                                                            <InfoIcon />
                                                        </Tooltip>
                                                    </FormControl>
                                                    {hasSmsCampaign ? (
                                                        <Heading p={0} m={0} pl={10} color={'darkmagenta'} fontSize={11}>Du har 10 gratis sms/dag</Heading>
                                                    ) : (
                                                        // <Heading p={0} m={0} pl={10} color={'darkmagenta'} fontSize={11}>Kostnad: 1 kr/sms</Heading>
                                                        <Heading p={0} m={0} pl={10} color={'darkmagenta'} fontSize={11}>Du har 10 gratis sms/dag</Heading>
                                                    )}
                                                </Box>

                                                <InputGroup >
                                                    <Textarea
                                                        maxHeight={150}
                                                        size={'lg'}
                                                        fontSize={16}
                                                        placeholder="Egen anteckning (visas endast för dig)"
                                                        id='description'
                                                        data-testid='personal-note-textarea'
                                                        {...methods.register('description')}
                                                    />
                                                    <InputRightElement
                                                        zIndex={1}
                                                        pointerEvents='none'
                                                        children={<Icon as={FaEnvelopeOpenText} color='gray.300' />}
                                                    />
                                                </InputGroup>
                                            </Stack>

                                        </Stack>
                                    </FormProvider>
                                </Stack>
                                <Box position={'fixed'} bg={'gray.100'} bottom={0} h={16} w={'100%'} boxShadow={'2xl'} zIndex={99}>
                                    <HStack spacing={4} padding={3} ml={4} justify='space-between'>
                                        <Button
                                            colorScheme='teal'
                                            size={'md'}
                                            variant='primary'
                                            type='submit'
                                            onClick={() => {
                                                click('Skapa bokning')
                                            }}
                                            isLoading={methods.formState.isSubmitting}
                                            data-testid='create-booking-button'
                                        >
                                            Skapa bokning
                                        </Button>

                                        {!isEmptyForm && (
                                            <Button
                                                colorScheme='teal'
                                                size={'md'}
                                                variant='outline'
                                                onClick={() => {
                                                    resetFormValues()
                                                }}
                                            >
                                                Rensa formulär
                                            </Button>
                                        )}
                                    </HStack>
                                </Box>
                            </form>
                        </CreateBookingProvider>
                    </DrawerBody>
                </DrawerContent>
            </Drawer >

            <Button
                leftIcon={<Icon as={FaPlus} textColor={'white'} />}
                colorScheme='teal'
                variant={'secondary'}
                size={'sm'}
                onClick={onOpen}>Ny bokning</Button>
        </Box >
    )
}

export default CreateBooking;