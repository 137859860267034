import { Button, Icon, Stack, Input, InputGroup, InputLeftElement, HStack, Text, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, InputRightElement, Heading, FormControl, FormErrorMessage, Box, Badge, Flex, Select, useDisclosure, TabPanel, TabPanels, Tabs, Tab, TabList, TabIndicator, Center, IconButton, useMediaQuery } from "@chakra-ui/react";
import React, { useCallback, useEffect } from "react";
import { FaCheck, FaEdit, FaFileUpload, FaMoneyBill, FaPaperclip, FaTrashAlt } from "react-icons/fa";
import { OfferEnquiry } from "../../Marketplace/types";
import { DayPicker } from "../../Marketplace/DayPickerV2";
import { useFormContext } from 'react-hook-form'
import { useCustomToast } from "../../../hooks/useCustomToast";
import HandInTimeIntervalSetting, { getFormattedTimeInterval } from "../../Settings/HandInTimeInverval";
import { SETTINGS, useCompanySettings } from "../../../hooks/api/companySettings";
import AddWorkDetailsForm from "./AddWorkDetailsForm";
import { useDropzone } from 'react-dropzone'
import { motion } from 'framer-motion'
import { BsChatText } from "react-icons/bs";
import OfferMessageModal from "../OfferMessageModal";

const MotionBox = motion(Box);

interface AddOfferConfirmationProps {
    file: any;
    setFile: (val: any) => void;
    onSaveOffer: any;
    enquiry: OfferEnquiry;
    fileUploadInputRef: React.MutableRefObject<any>;
    setHandInDates: (val: any) => void;
    handInDates: object;
    setTab?: (val: string) => void;
    tab?: string;
    unFinishedWorkArticleError?: boolean;
    setUnfinishedWorkArticleError?: (val: boolean) => void;
    articleRowRef?: React.MutableRefObject<any>;
    articleFieldError?: Record<string, boolean>;
    setArticleFieldError?: (val: Record<string, boolean>) => void;
}

const OfferForm: React.FC<AddOfferConfirmationProps> = ({ enquiry, file, setFile, onSaveOffer, fileUploadInputRef, setHandInDates, handInDates, tab, setTab, unFinishedWorkArticleError, setUnfinishedWorkArticleError, articleRowRef, articleFieldError, setArticleFieldError }) => {
    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useFormContext();
    const { onClose, onOpen, isOpen } = useDisclosure();
    const { infoToast } = useCustomToast();
    const { settingValue } = useCompanySettings(SETTINGS.HAND_IN_TIME_INTERVAL);
    const { fromTime, toTime } = getFormattedTimeInterval(settingValue)
    const { onOpen: onOpenMessageModal, isOpen: isOpenMessageModal, onClose: onCloseMessageModal } = useDisclosure();
    const message = getValues('message');
    const [isMobile] = useMediaQuery('(max-width: 492px)')

    useEffect(() => {
        setValue('availableTimeFrom', fromTime)
        setValue('availableTimeTo', toTime)
    }, [fromTime, toTime, setValue])

    useEffect(() => {
        const preferedWorkDetailsFormat = localStorage.getItem('preferedWorkDetailsFormat') === 'file' ? 'file' : 'form'
        setTab(preferedWorkDetailsFormat)
        setValue('workDetailsFormat', preferedWorkDetailsFormat)
    }, [setTab, setValue])

    const onDrop = useCallback(acceptedFiles => {
        const mimeType = acceptedFiles[0].type;
        const isPdf = mimeType.includes('pdf');
        if (!isPdf) {
            return infoToast('Du kan endast ladda upp .pdf-filer')
        }
        setFile(acceptedFiles[0])
    }, [setFile, infoToast])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return (
        <>
            <OfferMessageModal isOpen={isOpenMessageModal} onClose={onCloseMessageModal} />

            <form noValidate onSubmit={handleSubmit(onSaveOffer)}>
                <Stack spacing={4} padding={[4, 6]} pt={4}>
                    <Tabs defaultIndex={localStorage.getItem('preferedWorkDetailsFormat') === 'file' ? 1 : 0} variant='soft-rounded' size={'sm'} p={0} onChange={(index) => {
                        const value = index === 0 ? 'form' : 'file';
                        setTab(value);
                        setValue('workDetailsFormat', value);
                        localStorage.setItem('preferedWorkDetailsFormat', value);
                    }}>
                        <TabList gap={4} pb={4}>
                            <Tab _hover={{ color: 'teal.700' }}>Skapa arbetsdetaljer</Tab>
                            <Tab _hover={{ color: 'teal.700' }}>Ladda upp arbetsdetaljer (.pdf)</Tab>
                        </TabList>
                        <TabIndicator mt='-3px' height='2px' bg='blue.500' borderRadius='1px' />
                        <TabPanels>
                            <TabPanel p={0}>
                                <Stack spacing={2} pt={6}>
                                    <AddWorkDetailsForm articleFieldError={articleFieldError} setArticleFieldError={setArticleFieldError} articleRowRef={articleRowRef} selectedOfferEnquiry={enquiry} unFinishedWorkArticleError={unFinishedWorkArticleError} setUnfinishedWorkArticleError={setUnfinishedWorkArticleError} source="offer" />
                                </Stack>
                            </TabPanel>
                            <TabPanel p={0}>
                                {tab === 'file' && (
                                    <Stack spacing={4} pt={6}>
                                        <Box>
                                            <Heading size={'sm'}>Ladda upp fullständiga arbetsdetaljer i .pdf-format</Heading>
                                            <Text fontSize={12}>Från ditt arbetsorder-system (tex. AD Bildelar) kan du ladda upp ordern här som kunden därefter får tillgång till direkt i appen</Text>
                                        </Box>
                                        <Box pt={2}>
                                            <Stack {...getRootProps()} bg={isDragActive ? 'teal.50' : 'white'} spacing={4} height={200} padding={4} justifyContent={'center'} alignItems={'center'} border={!file ? '1px dashed gray' : '1px solid gray'} borderRadius={'lg'}>
                                                <Center bg={'teal.100'} borderRadius={'full'} w={12} h={12}>
                                                    <Icon as={file ? FaCheck : FaFileUpload} fontSize={24} color={!file ? 'teal.800' : 'teal.500'} />
                                                </Center>
                                                {file ? (
                                                    <MotionBox
                                                        initial={{ opacity: 0, y: 20 }}
                                                        animate={{ opacity: 1, y: 0 }}
                                                        transition={{ duration: 0.5, ease: "easeOut" }}
                                                    >
                                                        <Text fontSize={14} textAlign={'center'}>
                                                            <Text as={'span'} fontWeight={'bold'}>
                                                                Filen är uppladdad
                                                            </Text>
                                                            <Text fontSize={12}>Klicka på filen nedanför att se innehållet</Text>
                                                        </Text>
                                                    </MotionBox>
                                                ) : (
                                                    <Box>
                                                        <Stack align={'center'} spacing={0}>
                                                            <Text fontSize={14}><Text as={'span'} fontWeight={'bold'}>Dra hit</Text> <Text as={'span'} fontWeight={'bold'}></Text>eller <Text as={'span'} fontWeight={'bold'}>Ladda upp</Text> arbetsdetaljer som .pdf</Text>
                                                            <Text fontSize={12} color={'gray.500'}>Filen måste vara i .pdf-format (Max 5MB)</Text>
                                                        </Stack>

                                                        <HStack pt={2} align={'center'} justifyContent={'center'} spacing={4}>
                                                            <Button disabled={file} colorScheme='teal' variant={'ghost'} rightIcon={<Icon color="teal.500" as={FaPaperclip} />} size={'sm'} onClick={(e) => {
                                                                fileUploadInputRef.current.click();
                                                            }}>
                                                                <input
                                                                    {...getInputProps()}
                                                                    type="file"
                                                                    ref={fileUploadInputRef}
                                                                    accept=".pdf,application/pdf"
                                                                    onChange={e => {
                                                                        const mimeType = e.target.files[0].type;
                                                                        const isPdf = mimeType.includes('pdf');
                                                                        if (!isPdf) {
                                                                            return infoToast('Du kan endast ladda upp .pdf-filer')
                                                                        }
                                                                        setFile(e.target.files[0])
                                                                    }}
                                                                    style={{ display: 'none' }}
                                                                />
                                                                Ladda upp arbetsdetaljer
                                                            </Button>
                                                        </HStack>
                                                    </Box>
                                                )}
                                            </Stack>
                                        </Box>
                                        {file && (
                                            <HStack spacing={4} alignItems={'center'}>
                                                <Accordion allowToggle pt={2} w={'full'}>
                                                    <AccordionItem border={'none'} p={2} background='gray.50' borderRadius={'lg'} _hover={{ background: 'gray.100', borderRadius: 'lg' }}>
                                                        <AccordionButton>
                                                            <HStack width={'100%'} justifyContent={'space-between'}>
                                                                <HStack>
                                                                    <Icon color="teal.500" as={FaPaperclip} />
                                                                    <Text>{file?.name.trim()}</Text>
                                                                </HStack>
                                                                <AccordionIcon />
                                                            </HStack>
                                                        </AccordionButton>
                                                        <AccordionPanel pb={4}>
                                                            <embed src={URL.createObjectURL(file)} height={400} width={'100%'} type={'application/pdf'} />
                                                        </AccordionPanel>
                                                    </AccordionItem>
                                                </Accordion>
                                                <Icon color="red.500" as={FaTrashAlt} fontSize={20} cursor={'pointer'} onClick={() => setFile(null)} />
                                            </HStack>
                                        )}

                                        <FormControl isInvalid={'priceTotal' in errors} >
                                            <InputGroup size={'lg'}>
                                                <InputLeftElement
                                                    pointerEvents='none'
                                                    children={<Icon as={FaMoneyBill} color='gray.300' />}
                                                />
                                                <Input
                                                    type='number'
                                                    onFocus={(e) => e.target.addEventListener("wheel", (event) => event.preventDefault(), { passive: false })}
                                                    onBlur={(e) => e.target.removeEventListener("wheel", (event) => event.preventDefault())}
                                                    inputMode="numeric"
                                                    min={1}
                                                    placeholder='Totalt pris inkl. moms'
                                                    {...register('priceTotal', {
                                                        required: 'Du behöver ange totalpriset av hela arbetet',
                                                        onChange(event) {
                                                            return event.target.value;
                                                        }
                                                    })}
                                                />
                                                <InputRightElement opacity={.7} mr={4}>
                                                    <Text>SEK</Text>
                                                </InputRightElement>
                                            </InputGroup>

                                            <FormErrorMessage>
                                                {errors?.priceTotal?.message as string}
                                            </FormErrorMessage>
                                        </FormControl>

                                        {message?.length > 0 ? (
                                            <HStack align={'start'} pt={4}>
                                                <Text p={1.5} px={3} bg={'gray.100'} borderRadius={12} fontSize={14} whiteSpace={'pre-wrap'}>{message}</Text>
                                                <IconButton
                                                    colorScheme='gray'
                                                    aria-label='Edit message'
                                                    data-testid='edit-message-icon-button'
                                                    size={isMobile ? 'xs' : 'sm'}
                                                    icon={<FaEdit size={isMobile ? 10 : 16} />}
                                                    onClick={onOpenMessageModal} />
                                            </HStack>
                                        ) : (
                                            <HStack pt={3}>
                                                <HStack _hover={{ color: 'blue.400' }} cursor={'pointer'} onClick={onOpenMessageModal}>
                                                    <Heading fontSize={14} color='blue.600' data-testid='add-spare-parts-or-material'>Lägg till meddelande</Heading>
                                                    <Icon as={BsChatText} fontSize={16} color='blue.600' />
                                                </HStack>
                                            </HStack>
                                        )}

                                    </Stack>
                                )}

                            </TabPanel>
                        </TabPanels>
                    </Tabs>


                    <Stack pt={8} spacing={6} pb={24}>
                        <Heading size={'sm'}>Tillgänglighet</Heading>
                        <Stack spacing={0}>
                            <Text fontSize={12}>Uppskatta tidsåtgång</Text>
                            <FormControl isInvalid={'durationInDays' in errors}>
                                <InputGroup>
                                    <Select width={'100%'} size={'lg'} variant={'outline'} placeholder={'Välj antal arbetsdagar'} bg={'white'} borderRadius={'lg'} {...register('durationInDays', {
                                        required: 'Sätt ett ungefär på hur många dagar arbetet kommer ta att utföra',
                                        onChange(event) {
                                            return event.target.value;
                                        }
                                    })}>
                                        {Array.from({ length: 12 }, (v, i) => i).map((workDayAmount, i) => {
                                            return (
                                                <option value={workDayAmount} key={i}>{workDayAmount} dagar {workDayAmount === 0 && '(Färdig samma dag som inlämning)'}</option>
                                            )
                                        })}
                                    </Select>
                                </InputGroup>
                                <FormErrorMessage>
                                    {errors?.durationInDays?.message as string}
                                </FormErrorMessage>
                            </FormControl>

                        </Stack>
                        <Stack spacing={1} pt={2}>
                            <Heading size={'xs'}>Välj inlämningstid och datum</Heading>
                            <Text fontSize={12}>Kunden har önskat följande datum</Text>
                            {enquiry.selectedDays?.length === 0 ? (
                                <Box>
                                    <Badge colorScheme='teal' borderRadius={'full'} px={2} py={.8}>Tidigast möjligt</Badge>
                                </Box>
                            ) : (
                                <Flex wrap={'wrap'} width={600} >
                                    {enquiry.selectedDays?.map((day, index) => (
                                        <Box key={index} padding={'2px'}>
                                            <Badge colorScheme='teal' borderRadius={'full'} px={2} py={.8}>
                                                {day}
                                            </Badge>
                                        </Box>
                                    ))}
                                </Flex>
                            )}
                        </Stack>

                        <HStack align={'center'} spacing={4}>
                            <FormControl isInvalid={'availableTimeFrom' in errors}>
                                <Text fontSize={12}>Inlämning från</Text>
                                <InputGroup>
                                    <Input
                                        size={'lg'}
                                        type='time'
                                        defaultValue={fromTime}
                                        placeholder='Inlämningstid Från'
                                        {...register('availableTimeFrom', {
                                            required: 'Sätt en från-tid för när bilen kan hämtas',
                                            onChange(event) {
                                                return event.target.value;
                                            }
                                        })}
                                    />
                                </InputGroup>
                                <FormErrorMessage>
                                    {errors?.availableTimeFrom?.message as string}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={'availableTimeTo' in errors}>
                                <Text fontSize={12}>Inlämning till</Text>
                                <InputGroup>
                                    <Input
                                        type='time'
                                        size={'lg'}
                                        defaultValue={toTime}
                                        placeholder='Inlämningstid Till'
                                        {...register('availableTimeTo', {
                                            required: 'Sätt en till-tid för när bilen kan hämtas',
                                            onChange(event) {
                                                return event.target.value;
                                            }
                                        })}
                                    />
                                </InputGroup>
                                <FormErrorMessage>
                                    {errors?.availableTimeTo?.message as string}
                                </FormErrorMessage>
                            </FormControl>
                            <HandInTimeIntervalSetting onClose={onClose} onOpen={onOpen} isOpen={isOpen} />
                        </HStack>
                        <DayPicker datesPayload={handInDates} setDatesPayload={setHandInDates} selectedDays={enquiry.selectedDays} />
                    </Stack>
                </Stack>

                <Box position={'fixed'} bg={'gray.100'} bottom={0} h={16} w={'100%'} boxShadow={'2xl'} zIndex={99}>
                    <HStack spacing={4} padding={3} ml={4} justify={'space-between'}>
                        <Button
                            colorScheme='teal'
                            size={'md'}
                            variant='primary'
                            type='submit'
                        >
                            Bekräfta och skicka
                        </Button>
                    </HStack>
                </Box>
            </form>
        </>
    )
}

export default OfferForm;
