import { useToast, UseToastOptions } from "@chakra-ui/react"

export const useCustomToast = (options?: UseToastOptions) => {
    const toast = useToast();

    const commonProps: UseToastOptions = {
        duration: 3000,
        position: "top",
        isClosable: true,
        ...options
    }

    const successToast = (title: string) => toast({
        ...commonProps,
        title: title,
        status: 'success'
    })

    const errorToast = (title: string) => toast({
        ...commonProps,
        title: title,
        status: 'error',
    })

    const infoToast = (title: string, description?: string) => toast({
        ...commonProps,
        title: title,
        description: description,
        status: 'info',
    })

    return {
        successToast,
        infoToast,
        errorToast
    }
}