import { InfoIcon } from "@chakra-ui/icons";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Stack, Heading, Button, Text, Box, FormControl, FormLabel, Switch, Tooltip } from "@chakra-ui/react";
import { useState } from "react";
import { Booking } from "../../hooks/api/booking";
import { useCompanyFeatures } from "../../hooks/api/feature";

const FinishBookingModal: React.FC<{
    handleFinishBooking: (payload?: {}) => void;
    isLoadingFinishBooking: boolean;
    isOpen: boolean;
    onClose: () => void;
    booking: Booking
}> = ({ handleFinishBooking, isLoadingFinishBooking, isOpen, onClose, booking }) => {
    const { features } = useCompanyFeatures();
    const hasSmsCampaign = features?.some(feature => feature === 'free-sms-campaign')
    const [smsConfirmation, setSmsConfirmation] = useState<Boolean>(false);

    return (
        <Modal isOpen={isOpen} onClose={() => onClose()} size={'2xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Bekräfta att bilen {booking?.regNo} är färdig</ModalHeader>
                <ModalCloseButton />
                <ModalBody padding={6}>
                    <Stack spacing={12}>
                        <Box p={4} borderRadius={'2xl'} bg={'gray.100'}>
                            <Stack spacing={4}>
                                <Text fontSize={14}>När du avslutar arbetet kommer kunden automatiskt att notifieras att dem nu kan hämta upp sin bil på er verkstad under era öppettider. Mejl skickas alltid om du fyllt i kundens mejladdress när du skapad ny bokning. Däremot väljer du själv om du också vill skicka med sms. Vänligen kontakta kunden på eget sätt om varken telefonnummer eller mejl finns nedan.</Text>
                                <Box>
                                    <Heading size={'xs'}>Kundens Mejladress</Heading>
                                    <Text fontSize={14}>{booking?.email || 'saknas'}</Text>
                                </Box>
                                <Box>
                                    <Heading size={'xs'}>Kundens Telefonnummer</Heading>
                                    <Text fontSize={14}>{booking?.phoneNo || 'saknas'}</Text>
                                </Box>
                                <Box>
                                    <FormControl display='flex' alignItems='center'>
                                        <Switch id='smsConfirmation' disabled={!booking?.phoneNo} onChange={e => setSmsConfirmation(e.target.checked)} />
                                        <FormLabel htmlFor='smsConfirmation' mb='0' ml={2} fontSize={14}>
                                            Skicka sms-bekräftelse till kund?
                                        </FormLabel>
                                        <Tooltip
                                            hasArrow
                                            bg='teal.600'
                                            color='white'
                                            placement='top'
                                            label={!booking?.phoneNo ? 'För att skicka sms bekräftelse automatiskt så måste du fylla i kundens telefonnummer när du skapar en bokning.' : 'Ett sms skickas automatiskt till kund med en bekräftelse att bilen är färdig och redo att hämtas upp. '}
                                        >
                                            <InfoIcon />
                                        </Tooltip>
                                    </FormControl>
                                    {hasSmsCampaign ? (
                                        <Heading p={0} m={0} pl={10} color={'darkmagenta'} fontSize={11}>Du har 10 gratis sms/dag</Heading>
                                    ) : (
                                        // <Heading p={0} m={0} pl={10} color={'darkmagenta'} fontSize={11}>Kostnad: 1 kr/sms</Heading>
                                        <Heading p={0} m={0} pl={10} color={'darkmagenta'} fontSize={11}>Du har 10 gratis sms/dag</Heading>
                                    )}
                                </Box>

                            </Stack>
                        </Box>
                        <Button
                            w="200px"
                            variant={'primary'}
                            size="sm"
                            onClick={() => handleFinishBooking({ smsConfirmation })}
                            isLoading={isLoadingFinishBooking}
                        >
                            Avsluta arbetet
                        </Button>
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    )

}


export default FinishBookingModal;