
import { Stack, Heading, Box, HStack, Text, Th, Td, Tr, Tbody, Table, Thead, Tooltip, Divider } from '@chakra-ui/react';
import React from 'react';
import { WorkArticleDetailsData } from './Marketplace/types';
import { VALUE_TYPE, formatMoney } from './modals/AddOffer/AddWorkDetailsForm';
import { InfoIcon } from '@chakra-ui/icons';

const WorkArticleDetails: React.FC<{ data: WorkArticleDetailsData; onlyLaborSection?: boolean; }> = ({ data, onlyLaborSection }) => {
    const { laborCost, laborHours, laborTotalCost, workArticles, vat, priceTotalFormatted, isOfferByFormValues, addonArticlePercentageCost, totalAddonCost } = data

    const sumArticleCost = workArticles?.reduce((acc, article) => acc + article.articleAmount * (article.articlePrice as number) * (1 + addonArticlePercentageCost / 100), 0);
    const shouldDisplaySpareParts = workArticles?.filter(article => article.valueType === VALUE_TYPE.SPARE_PARTS).length === 0;

    if (!isOfferByFormValues && !onlyLaborSection) {
        return null;
    }

    function renderLaborCostSection() {
        return (
            <Stack pr={4} pl={4} spacing={4}>
                <HStack justify={'space-between'}>
                    <Box>
                        <Text fontSize={16}>{`Arbetskostnad ${onlyLaborSection ? 'felsökning' : ''}`}</Text>
                        <Text fontSize={14}>{laborHours} timmar à {laborCost} kr</Text>
                    </Box>
                    <Text fontSize={16}>{laborTotalCost}</Text>
                </HStack>

                <Divider />

                <Stack spacing={1}>
                    <HStack justify={'space-between'}>
                        <Heading size={'sm'}>Totalt pris <Text fontSize={12} fontWeight={'normal'} as='span'>inkl. moms</Text></Heading>
                        <Heading size={'sm'}>{onlyLaborSection ? laborTotalCost : priceTotalFormatted}</Heading>
                    </HStack>
                    <HStack justify={'space-between'}>
                        <Text fontSize={14}>{'Varav moms (25%)'}</Text>
                        <Text fontSize={14}>{vat}</Text>
                    </HStack>
                </Stack>
            </Stack>
        )
    }

    if (onlyLaborSection) {
        return renderLaborCostSection()
    }

    return (
        <Stack spacing={4}>
            {shouldDisplaySpareParts && (
                <Table variant="simple" size="sm">
                    <Thead>
                        <Tr>
                            <Th fontSize={14}>Benämning</Th>
                            <Th fontSize={14} isNumeric>Antal</Th>
                            <Th fontSize={14} isNumeric>À-Pris</Th>
                            <Th fontSize={14} isNumeric>Påslag</Th>
                            <Th fontSize={14} isNumeric>Belopp</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {workArticles?.filter(article => article.valueType !== VALUE_TYPE.SPARE_PARTS)?.map((article, i) => {
                            const { articleAmount, articleTotalPrice, articleName, articleUnit, articlePrice } = article;
                            return (
                                <Tr key={i}>
                                    <Td fontSize={16}>{articleName}</Td>
                                    <Td fontSize={16} isNumeric>
                                        {articleAmount} {articleUnit.label}
                                    </Td>
                                    <Td fontSize={16} isNumeric>{formatMoney(articlePrice)}</Td>
                                    <Td fontSize={16} isNumeric>{addonArticlePercentageCost || 0}%</Td>
                                    <Td fontSize={16} isNumeric>{articleTotalPrice}</Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            )}

            <Stack>
                <Box pr={4} pl={4}>
                    <HStack justify={'space-between'} align='start'>
                        <Text>Materialkostnad</Text>
                        <Text data-testid='material-cost-text'>{formatMoney(sumArticleCost)}</Text>
                    </HStack>
                    <HStack justify={'space-between'} align='start'>
                        <HStack>
                            <Text fontSize={14}>Varav påslag ({addonArticlePercentageCost || 0}%)</Text>
                            <Tooltip hasArrow placement='top' bg={'teal.800'} color={'white'} borderRadius={12} label='Påslaget på reservdelar syns inte för kunden'>
                                <InfoIcon fontSize={14} />
                            </Tooltip>
                        </HStack>
                        <Text fontSize={14} data-testid='labour-cost-text'>{formatMoney(totalAddonCost)}</Text>
                    </HStack>
                </Box>
                {renderLaborCostSection()}

            </Stack>
        </Stack>
    )
}

export default WorkArticleDetails;