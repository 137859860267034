import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, extendTheme, defineStyleConfig, defineStyle } from '@chakra-ui/react'
import "@fontsource/montserrat"
import "@fontsource/montserrat/600.css"
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn: "https://287f9279753a01b47a27d7076d511dee@o4508161871446016.ingest.de.sentry.io/4508161882062928",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.browserProfilingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/app.bilgo\.se\//, /^https:\/\/app-alfa.bilgo\.se\//],
//   // Set profilesSampleRate to 1.0 to profile every transaction.
//   // Since profilesSampleRate is relative to tracesSampleRate,
//   // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
//   // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
//   // results in 25% of transactions being profiled (0.5*0.5=0.25)
//   profilesSampleRate: 1.0,
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });



const theme = extendTheme({
  styles: {
    global: () => ({
      body: {
        fontFamily: 'Montserrat'
      },
    }),
  },
  components: {
    Button: {
      baseStyle: { _focus: { boxShadow: 'none' } },
      variants: {
        'primary': {
          bg: 'teal.700',
          color: 'white',
          _hover: {
            bg: 'teal.600'
          },
          _active: {
            bg: 'teal.800'
          },
          _disabled: {
            bg: 'teal.700',
            opacity: 0.5,
            _hover: {
              bg: 'teal.700!important',
              opacity: 0.5
            }
          }
        },
        'secondary': {
          bg: 'blue.600',
          color: 'white',
          _hover: {
            bg: 'blue.700'
          },
          _disabled: {
            bg: 'blue.600',
            opacity: 0.5,
            _hover: {
              bg: 'blue.600!important',
              opacity: 0.5
            }
          }
        }
      }
    },
    IconButton: { baseStyle: { _focus: { boxShadow: 'none' } } },
    Accordion: { baseStyle: { _focus: { boxShadow: 'none' } } },
    Heading: defineStyleConfig({
      baseStyle: { color: 'captionText', fontFamily: 'Montserrat', fontWeight: '600' },
      variants: {
        'light': defineStyle({ color: 'blackAlpha.800' })
      }
    }),
  }
});

export default theme;

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ChakraProvider>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
