import { Badge, Box, Button, Flex, Heading, HStack, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text } from '@chakra-ui/react';
import moment from 'moment';
import * as React from 'react';
import { FaPaperclip } from 'react-icons/fa';
import { getFirebaseImageUrl } from '../../utils/firebase';
import WorkArticleDetails from '../WorkArticleDetails';
import { Offer } from './types';
import { useMessaging } from '../../hooks/api/booking';


const OfferModal: React.FC<{ onClose: () => void; isOpen: boolean; offer: Offer }> = ({ onClose, isOpen, offer }) => {
    const messagingResponse = useMessaging(offer?.offerId || null);
    const messages = messagingResponse.data;

    if (!offer) {
        return null;
    }

    const { laborTotalCost, isOfferByFormValues, workArticles, priceTotalFormatted, laborCost, vat, laborHours, addonArticlePercentageCost, totalAddonCost } = offer;

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'2xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <HStack justify={'space-between'} pr={12}>
                        <Heading size={'md'}>Offert</Heading>
                        <Box>
                            <HStack align={'start'} spacing={1}>
                                <Text fontSize={12}>Skickad</Text>
                                <Text fontSize={12}>{moment(offer.createdAt).locale('sv').format('LLLL')}</Text>
                            </HStack>
                        </Box>
                    </HStack>
                </ModalHeader>

                <ModalCloseButton />
                <ModalBody pl={8} pr={8} pb={8} pt={0} mt={0}>
                    <Stack spacing={4}>
                        <Box>
                            <Heading pt={4} size={'sm'}>Inlämningsdatum</Heading>
                            <Text fontSize={16}>Följande inlämningsdatum har skickats till kund.</Text>
                            <Stack mt={1}>
                                <Flex wrap={'wrap'} width={600}>
                                    {offer?.availableDates?.map((date, index) => (
                                        <Box key={index} padding={'2px'}>
                                            <Badge colorScheme='purple' borderRadius={'full'} px={1.5} py={0.5}>
                                                {date}
                                            </Badge>
                                        </Box>
                                    ))}
                                </Flex>
                            </Stack>
                        </Box>
                        <Box>
                            <HStack justify={'space-between'}>
                                <Box>
                                    <Heading pt={4} size={'sm'}>Inlämningstid</Heading>
                                    <Text fontSize={16}>Följande tid kommer kunden lämna in bilen hos dig</Text>
                                </Box>
                                <Heading size={'sm'}>{offer.availableTime}</Heading>
                            </HStack>
                        </Box>

                        <Box>
                            <Heading pt={4} size={'sm'}>Tidsåtgång</Heading>
                            <Text fontSize={16}>Ni har beräknat arbetet att ta ungefär ( {offer.durationInDays} ) dagar att genomföra</Text>
                        </Box>

                        {messages?.length > 0 && (
                            <Stack pt={4}>
                                <Heading size={'sm'}>Meddelanden</Heading>
                                <HStack align={'start'}>
                                    <Text p={1.5} px={3} bg={'gray.100'} borderRadius={12} fontSize={14} whiteSpace={'pre-wrap'}>{messages[0].content}</Text>
                                </HStack>
                            </Stack>
                        )}

                        {isOfferByFormValues ? (
                            <Stack>
                                <Heading size={'sm'} pt={4}>Arbetsdetaljer</Heading>
                                <WorkArticleDetails data={{ laborTotalCost, isOfferByFormValues, workArticles, priceTotalFormatted, laborCost, vat, laborHours, addonArticlePercentageCost, totalAddonCost }} />
                            </Stack>

                        ) : (
                            <Stack>
                                <Heading pt={4} size={'sm'}>Bifogade arbetsdetaljer i filformat</Heading>

                                <HStack>
                                    <Icon color="gray.500" as={FaPaperclip} />

                                    <a style={{ fontSize: 14 }} target="_blank" rel="noreferrer" href={getFirebaseImageUrl(offer.fileName)}>
                                        <Button colorScheme='blue' variant='link' size={'sm'}>
                                            {offer.fileName.substring(0, 70)}...
                                        </Button>
                                    </a>
                                </HStack>
                            </Stack>
                        )}
                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <Button
                        w="200px"
                        size="sm"
                        variant={'primary'}
                        onClick={onClose}
                    >
                        Stäng
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default OfferModal;